import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import AlumniCard from "../components/alumni/AlumniCard";
import TableUI from '../components/table/Table';
import CurriculumProgress from "../components/alumni/CurriculumProgress";
import { fetchAcademicHistory, fetchCurriculumProgress } from "../api";
import ErrorComponent from "../components/common/Error";
import LoadingComponent from "../components/common/Loading";

const headers = [
  {
    id: 'clave',
    text: 'Clave',
    width: '15%',
    textAlign: 'start',
  },
  {
    id: 'nombreMateria',
    text: 'Materia',
    width: '30%',
    textAlign: 'start',
  },
  {
    id: 'creditos',
    text: 'Créditos',
    width: '10%',
    textAlign: 'end',
  },
  {
    id: 'calificacionFinalAcreditacion',
    text: 'Calificación',
    width: '20%',
    textAlign: 'end',
  },
  {
    id: 'nombrePeriodo',
    text: 'Periodo',
    width: '10%',
    textAlign: 'end',
  },
  {
    id: 'estatus',
    text: 'Estatus',
    width: '20%',
    textAlign: 'start',
  },
];

const AcademicRecordPage = () => {
  const { externalId } = useParams();
  const [history, setHistory] = useState([]);
  const [alumni, setAlumni] = useState({});
  const [progress, setProgress] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCurriculumProgress(externalId)
      .then(data => {
        setAlumni(data.data.perfilDashboardAlumno);
        setProgress(data.data.avanceProgramaDashboardAlumno);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => { 
    fetchAcademicHistory(externalId)
      .then(data => {
        setHistory(data.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent />;

  return (
    <div className="flex justify-start">
      <div className="w-3/5 p-4 place-content-center">
        <div className="py-4 text-left">
          <h1 className="text-3xl font-bold">
            Historial Académico
          </h1>
          <AlumniCard 
            alumni={alumni}
            gpa={progress.promedioGeneral} />
          <CurriculumProgress progress={progress} />
          <div className="mt-3">
            <TableUI
              headers={headers}
              rows={history}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcademicRecordPage;