import { Responsive, Table } from '@instructure/ui'; 

const TableUI = ({ headers, rows, caption = 'Registros' }) => {

  return (
    <Responsive
      query={{
        small: { maxWidth: '40rem' },
        large: { minWidth: '41rem' },
      }}
      props={{
        small: { layout: 'stacked' },
        large: { layout: 'fixed' },
      }}
    >
      {({ layout }) => (
        <div>
          <Table
            hover={true}
            layout={layout}
            caption={caption}
          >
            <Table.Head>
              <Table.Row>
                {(headers || []).map(({ id, text, width, textAlign }) => (
                  <Table.ColHeader
                    key={`h-${Math.floor(Math.random()*10**5)}`}
                    id={`h-${Math.floor(Math.random()*10**5)}`}
                    width={width}
                    textAlign={textAlign}
                  >
                    {text}
                  </Table.ColHeader>
                  ))}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {rows.map((row) => (
                <Table.Row key={`r-${Math.floor(Math.random()*10**5)}`}>
                  {headers.map(({ id, renderCell, textAlign }) => (
                    <Table.Cell
                      key={`c-${Math.floor(Math.random()*10**5)}`}
                      textAlign={layout === 'stacked' ? 'start' : textAlign}
                    >
                      {renderCell ? renderCell(row[id], layout) : row[id]}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </Responsive>
  );
}

export default TableUI;