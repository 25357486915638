import React from "react";
import { Button, IconExportContentLine, IconPrinterLine, Metric, Text, View } from "@instructure/ui";

const AlumniCard = ({ alumni, gpa }) => {
  return (
    <div className="card grid grid-cols-7 gap-4">
      <div className="col-span-1">
        <View
          display="inline-block"
          width="3.5rem"
          height="3.5rem"
          margin="small"
          padding="medium"
          background="primary-inverse"
          borderRadius="circle"
          textAlign="center"
        />
      </div>
      <div className="col-span-3">
        <Text weight="bold">
          { alumni.nombreCompleto }  
        </Text><br/>
        <Metric textAlign="start" renderLabel="Promedio" renderValue={gpa}/>
      </div>
      <div className="col-span-3 flex flex-row gap-3 align-start">
        <Button
          renderIcon={IconPrinterLine}
          display="block"
          textAlign="center"
          color="primary-inverse"
        >
          Imprimir
        </Button>
        <Button
          renderIcon={IconExportContentLine}
          display="block"
          textAlign="center"
          color="primary"
        >
          Exportar
        </Button>
      </div>
    </div>
  );
};

export default AlumniCard;