import { Link, List } from "@instructure/ui";
import React from "react";

const HomePage = () => {
  return (
    <div className="flex justify-start">
      <div className="w-3/5 p-4 place-content-center bg-sky-800">
        <div className="py-4 text-left">
          <h1 className="text-3xl font-bold text-white">
            Explora las opciones del menu
          </h1>
          <div className="py-1 mt-6">
            <div className="card">
              <List isUnstyled>
                <List.Item>
                  <Link href="/grades/">
                    Calificaciones
                  </Link>
                </List.Item>
                <List.Item>
                  <Link href="/bank-statement/">
                    Estado de cuenta
                  </Link>
                </List.Item>
                <List.Item>
                  <Link href="/academic-record/">
                    Historial Académico
                  </Link>
                </List.Item>
              </List>
            </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;