import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { Button, Heading, IconPrinterLine } from '@instructure/ui';
import { Select } from '@instructure/ui-select';
import TableUI from '../components/table/Table';
import { fetchGrades, fetchPeriods } from "../api";
import ErrorComponent from "../components/common/Error";
import LoadingComponent from "../components/common/Loading";

const headers = [
  {
    id: 'grupoId',
    text: 'Grupo',
    width: '15%',
    textAlign: 'start',
  },
  {
    id: 'clave',
    text: 'Clave',
    width: '15%',
    textAlign: 'start',
  },
  {
    id: 'nombre',
    text: 'Nombre',
    width: '40%',
    textAlign: 'start',
  },
  {
    id: 'promedioPrograma',
    text: 'Promedio',
    width: '10%',
    textAlign: 'end',
  },
  {
    id: 'faltasFinal',
    text: 'Faltas',
    width: '10%',
    textAlign: 'end',
  },
  {
    id: 'estatus',
    text: 'Estatus',
    width: '10%',
    textAlign: 'end',
  },
];

const GradesPage = () => {
  const { externalId } = useParams();
  const [inputValue, setInputValue] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [periodId, setPeriodId] = useState(null);
  const [grades, setGrades] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (periodId) {
      setLoading(true);
      fetchGrades(externalId, periodId)
        .then(data => {
          setGrades(data.data);
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    }
  }, [periodId]);

  useEffect(() => {
    fetchPeriods(externalId)
      .then(data => {
        const mapped = data.data.map(e => {
          return {
            id: `${e.periodoId}`,
            label: e.nombre
          }
        });
        setPeriods(mapped);
        setPeriodId(mapped?.[0]?.id)
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const getOptionById = queryId => {
    return periods.find(({ id }) => id === queryId)
  }

  const handleShowOptions = () => {
    setShowOptions(true);
  };

  const handleHideOptions = event => {
    setShowOptions(false);
    setInputValue('');
  }

  const handleSelectOption = (event, { id }) => {
    const option = getOptionById(id).label
    setPeriodId(getOptionById(id).id);
    setInputValue(option);
    setShowOptions(false);
  }

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent />;
  return (
    <div className="flex justify-start">
      <div className="max-w-screen-lg p-4 place-content-center">
          <div className="grid grid-cols-3 gap-4 py-3">
            <div className="col-span-2">
              <Heading level="h3" margin="0 0 x-small">Calificaciones</Heading>
            </div>
            <div className="col-span-1 flex flex-row gap-4">
              <Select 
                renderLabel="" 
                placeholder="Periodo"
                isShowingOptions={showOptions}
                inputValue={inputValue}
                onRequestShowOptions={handleShowOptions}
                onRequestHideOptions={handleHideOptions}
                onRequestSelectOption={handleSelectOption}
                width={'300px'}
                >
                  {periods.map((option) => {
                    return (
                      <Select.Option
                        id={option.id}
                        key={option.id}
                      >
                        { option.label }
                      </Select.Option>
                    )
                  })}
              </Select>
              <Button
                renderIcon={IconPrinterLine}
                display="block"
                textAlign="center"
                color="primary"
              >
                Imprimir
              </Button>
            </div>
          </div>
          {grades.length > 0 &&
          <TableUI
            headers={headers}
            rows={grades}
          />
          }
      </div>
    </div>
  );
};

export default GradesPage;