import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const fetchPeriods = async externalId => {
    try {
        const response = await axios.get(`${API_BASE_URL}/PeriodosByExternalId?ExternalId=${externalId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchGrades = async (externalId, periodId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/BoletaByExternalId_PeriodoId?ExternalId=${externalId}&PeriodoId=${periodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchBankStatement = async externalId => {
    try {
        const response = await axios.get(`${API_BASE_URL}/ResumenEstadoCuentaByExternalId?ExternalId=${externalId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchBankMovements = async (externalId, periodId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/EstadoCuentaByExternalId?ExternalId=${externalId}&PeriodoId=${periodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchCurriculumProgress = async externalId => {
    try {
        const response = await axios.get(`${API_BASE_URL}/AvanceAcademicoByExternalId?ExternalId=${externalId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchAcademicHistory = async externalId => {
    try {
        const response = await axios.get(`${API_BASE_URL}/HistorialAcademicoByExternalId?ExternalId=${externalId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};
