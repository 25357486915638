import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { Button, Heading, Metric, Select, IconExportContentLine } from '@instructure/ui';
import { Text } from '@instructure/ui-text'
import TableUI from '../components/table/Table';
import { fetchBankStatement, fetchBankMovements, fetchPeriods } from "../api";
import ErrorComponent from "../components/common/Error";
import LoadingComponent from "../components/common/Loading";
import { formatCurrency, formatDate } from "../lib/utils";

const headers = [
  {
    id: 'fechaVencimiento',
    text: 'Vencimiento',
    width: '15%',
    textAlign: 'start',
    renderCell: e => (formatDate(e))
  },
  {
    id: 'descripcion',
    text: 'Descripción',
    width: '50%',
    textAlign: 'start',
  },
  {
    id: 'saldoCargo',
    text: 'Cargo',
    width: '10%',
    textAlign: 'end',
    renderCell: e => (formatCurrency(e))
  },
  {
    id: 'saldoAbono',
    text: 'Abono',
    width: '10%',
    textAlign: 'end',
    renderCell: e => (formatCurrency(e))
  },
  {
    id: 'saldoActual',
    text: 'Saldo',
    width: '10%',
    textAlign: 'end',
    renderCell: e => (formatCurrency(e))
  },
];

const BankStatementPage = () => {

  const { externalId } = useParams();
  const [bankStatement, setBankStatement] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [periods, setPeriods] = useState([]);
  const [periodId, setPeriodId] = useState(null);
  const [movements, setMovements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBankStatement(externalId)
      .then(data => {
        setBankStatement(data.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if(periodId){
      fetchBankMovements(externalId, periodId)
        .then(data => {
          setMovements(data.data);
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    }
  }, [periodId]);

  useEffect(() => {
    fetchPeriods(externalId)
      .then(data => {
        const mapped = data.data.map(e => {
          return {
            id: `${e.periodoId}`,
            label: e.nombre
          }
        });
        setPeriods(mapped);
        setPeriodId(mapped?.[0]?.id)
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const getOptionById = queryId => {
    return periods.find(({ id }) => id === queryId)
  }
  
  const handleShowOptions = () => {
    setShowOptions(true);
  };

  const handleHideOptions = event => {
    setShowOptions(false);
    setInputValue('');
  }

  const handleSelectOption = (event, { id }) => {
    const option = getOptionById(id).label
    setPeriodId(getOptionById(id).id);
    setInputValue(option);
    setShowOptions(false);
  }

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent />;

  return (
    <div className="flex justify-start">
      <div className="max-w-screen-lg p-4 place-content-center">
        <div className="py-4">
          <h1 className="text-3xl font-bold">
            Estado de cuenta
          </h1>
          <div className="flex flex-row">
            <Text 
              weight="light"
              size="x-small">
              Vigencia de consulta: &nbsp;
            </Text>
            <Text size="x-small"> { new Date().toString() }</Text>
          </div>
        </div>
        {bankStatement && 
        <div className="grid grid-cols-3 gap-4 py-1">
          <div className="card mr-1">
            <Metric 
              renderLabel="Saldo vencido" 
              renderValue={`$${bankStatement.saldoVencido}`}
            />
          </div>
          <div className="card mx-0">
            <Metric 
              renderLabel="Pagar antes de fin de mes"
              renderValue={`$${bankStatement.saldoAPagar}`}
            />
          </div>
          <div className="card ml-1">
            <Metric 
              renderLabel="Saldo total" 
              renderValue={`$${bankStatement.saldoTotal}`}
            />
          </div>
      </div>
        }
        <div className="grid grid-cols-3 gap-4 py-3">
          <div className="col-span-1">
            <Heading level="h3" margin="0 0 x-small">Movimientos</Heading>
          </div>
          <div className="col-span-2 flex flex-row gap-4">
            <Select 
              renderLabel="" 
              placeholder="Periodo"
              isShowingOptions={showOptions}
              inputValue={inputValue}
              onRequestShowOptions={handleShowOptions}
              onRequestHideOptions={handleHideOptions}
              onRequestSelectOption={handleSelectOption}
              width={'300px'}
              >
                {periods.map((option) => {
                  return (
                    <Select.Option
                      id={option.id}
                      key={option.id}
                    >
                      { option.label }
                    </Select.Option>
                  )
                })}
            </Select>
            <Button
              renderIcon={IconExportContentLine}
              display="block"
              textAlign="center"
              color="primary"
            >
              Exportar a Excel
            </Button>
          </div>
        </div>
        <TableUI
          headers={headers}
          rows={movements}
        />
      </div>
    </div>
  );
};

export default BankStatementPage;