import React from "react";
import { InstUISettingsProvider, canvas } from '@instructure/ui'; 
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import GradesPage from './pages/GradesPage';
import BankStatementPage from './pages/BankStatementPage';
import AcademicRecordPage from './pages/AcademicRecordPage';
import NotFoundPage from './pages/NotFoundPage';
import HomePage from "./pages/HomePage";

const App = () => { 

  return (
    <div style={{ backgroundColor: '#FFFFFF', height: '100%' }}>
      <InstUISettingsProvider theme={canvas}> 
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/grades/:externalId" element={<GradesPage />} />
            <Route path="/bank-statement/:externalId" element={<BankStatementPage />} />
            <Route path="/academic-record/:externalId" element={<AcademicRecordPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </InstUISettingsProvider> 
    </div>
  );
} 
  
export default App;