import { Metric } from "@instructure/ui";
import React from "react";

const CurriculumProgress = ({ progress }) => {
  return (
    <div className="flex justify-start">
      <div className="w-full place-content-center">
        <div className="py-1 text-left">
          <div className="grid grid-cols-4 gap-4 py-1">
            <div className="card">
              <Metric 
                renderLabel="Avance General" 
                renderValue={`${progress.avance}%`}
              />
            </div>
            <div className="card">
              <Metric 
                renderLabel="Materias Aprobadas" 
                renderValue={`${progress.materiasAprobadas}`}
              />
            </div>
            <div className="card">
              <Metric 
                renderLabel="Materias Restantes" 
                renderValue={`${progress.materiasRestantes}`}
              />
            </div>
            <div className="card">
              <Metric 
                renderLabel="Promedio General" 
                renderValue={`${progress.promedioGeneral}`}
              />
            </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default CurriculumProgress;