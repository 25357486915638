import { Alert } from '@instructure/ui';

const ErrorComponent = () => (
  <Alert
    variant="error"
    renderCloseButtonLabel="Close"
    margin="small"
  >
    Error al cargar los datos, intenta más tarde.
  </Alert>
);

export default ErrorComponent;